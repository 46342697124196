import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import LinkedinIcon from "../../../assets/linkedin-leadership.svg"
import NorhartBlackCircleIcon from "../../../assets/norhart-black-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const FundLeadershipMikeSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: "#365c70",
      }}
    >
      {/* <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartBlackCircleIcon />
        </div> */}

      {/* <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
         EXECUTIVE MANAGEMENT
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#333333" }}>
          16 years of innovation & leadership
        </h3> */}
      <h4 className="contentHeroSubTitle" style={{ color: "#333333", opacity: "0.8" }}></h4>

      <div className="row ms-5 me-5">
        <div className="col-sm mt-5">
          <div className="ibox-content product-box bct-3 animate__animated animate__slideOutUp">
            <div className="ibox">
              <div>
                <img
                  src="/fund/leadership/stacy.png"
                  alt="Stacy Ekholm"
                  loading="lazy"
                  decoding="async"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="product-desc">
              <small className="text-muted">LEADERSHIP</small>
              <span className="product-name">Stacy Ekholm</span>
              <div className="small m-t-xs">Chief Operations Officer</div>
              <div className="small m-t-xs mt-4">
                <div className="small m-t-xs mt-4">
                  <a href="/fund/leadership/stacy-ekholm" rel="noreferrer">
                    <button type="button" className=" btn btn-dark btn-outline btn-md">
                      Learn More
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm mt-5">
          <div className="ibox-content product-box bct-3 animate__animated animate__slideOutUp">
            <div className="ibox">
              <div>
                <img
                  src="/fund/leadership/tim.png"
                  alt="Tim Libertini"
                  loading="lazy"
                  decoding="async"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="product-desc">
              <small className="text-muted">LEADERSHIP</small>
              <span className="product-name">Tim Libertini</span>
              <div className="small m-t-xs">Chief Finanical Officer</div>
              <div className="small m-t-xs mt-4">
                <div className="small m-t-xs mt-4">
                  <a href="/fund/leadership/tim-libertini" rel="noreferrer">
                    <button type="button" className=" btn btn-dark btn-outline btn-md">
                      Learn More
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm mt-5">
          <div className="ibox-content product-box bct-3 animate__animated animate__slideOutUp">
            <div className="ibox">
              <div>
                <img
                  src="/fund/leadership/marie.png"
                  alt="Marie Dickover"
                  loading="lazy"
                  decoding="async"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="product-desc">
              <small className="text-muted">LEADERSHIP</small>
              <span className="product-name">Marie Dickover</span>
              <div className="small m-t-xs">Chief Development Officer</div>
              <div className="small m-t-xs mt-4">
                <div className="small m-t-xs mt-4">
                  <a href="/fund/leadership/marie-dickover" rel="noreferrer">
                    <button type="button" className=" btn btn-dark btn-outline btn-md">
                      Learn More
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default FundLeadershipMikeSection
